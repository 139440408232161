import { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import useFetch from '../useFetch';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import useLoader from '../useLoader';

const useValidateCreateNewUser = () => {
  const endpoint = useMemo(() => {
    return cloneDeep(endPointsMapper.VALIDATE_CREATE_NEW_USER);
  }, []);

  const { doFetch, loading } = useFetch(endpoint, undefined);

  useLoader(loading);

  const validateCreateNewUser = async ({ emailIds }) => {
    endpoint.path = `${cloneDeep(endPointsMapper.VALIDATE_CREATE_NEW_USER).path}?emailIds=${emailIds}`;
    return doFetch();
  };

  return { loading, validateCreateNewUser };
};

export default useValidateCreateNewUser;
