import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import useUserMaintenance from '../../../context';
import FieldRenderer from '../../FieldRenderer';
import { UPDATE_FIELDS, VALIDATE_INPUT, UPDATE_PAGE_EDITED, UPDATE_SINGLE_FIELD } from '../../../actions';
import { TEXT, TEXT_WITH_SWITCH, AUTOCOMPLETE_USER_DETAILS, CREATE_USER } from '../../../constants';
import translator from '../../../../../../services/translator';

const { translate: t } = translator;

const ProfileGroup = ({ data, groupTitle, testId }) => {
  const {
    state: { isPageEdited, isEditable, userDetails, isInputValid, dataImportedFromIdHub },
    storeValues: { isInternalUser, editModeDropdownData, pageKey },
    dispatch,
  } = useUserMaintenance();

  const handleChange = ({ event: { type } = {}, value, config: { editableFieldType, validation, value: path, required } = {}, errorMessage }) => {
    if (type === 'change' && [TEXT, TEXT_WITH_SWITCH].includes(editableFieldType)) {
      if (!isPageEdited) {
        dispatch({ type: UPDATE_PAGE_EDITED, payload: { isPageEdited: true } });
      }
    } else {
      if (editableFieldType === AUTOCOMPLETE_USER_DETAILS && userDetails?.organizationId !== value?.organizationId) {
        dispatch({ type: UPDATE_SINGLE_FIELD, payload: value });
      } else {
        dispatch({ type: UPDATE_FIELDS, payload: { newValue: value, path } });
      }
      if (validation || required) {
        dispatch({ type: VALIDATE_INPUT, payload: { fieldName: path, isValid: !errorMessage, errorMessage } });
      }
    }
  };

  return (
    <div className='profile-main-container__info-section-container'>
      <div data-testid={testId} className='profile-main-container__header-title'>
        {t(groupTitle)}
      </div>
      {data.map((coreItem, idx, array) => {
        const edit =
          isEditable &&
          (coreItem.editable ? coreItem.editable(userDetails, pageKey) && (coreItem.unmodifiedAfterImport ? !dataImportedFromIdHub : true) : true);
        const value = coreItem.valueGetter
          ? coreItem.valueGetter(userDetails, pageKey)
          : get(userDetails, Array.isArray(coreItem.value) ? coreItem.value : coreItem.value.split('.'));

        return (coreItem.isProtectedForUser && !isInternalUser) || (coreItem.hideOnEdit && pageKey !== CREATE_USER) ? null : (
          <FieldRenderer
            key={coreItem.id}
            isEditable={edit}
            config={coreItem}
            isPageEdited={isPageEdited}
            handleChange={handleChange}
            value={value}
            editModeDropdownData={editModeDropdownData}
            errorState={isInputValid[coreItem.value]}
            sectionStart={idx === 0}
            sectionEnd={idx === array.length - 1}
          />
        );
      })}
    </div>
  );
};

ProfileGroup.propTypes = {
  data: PropTypes.array,
  groupTitle: PropTypes.string,
  testId: PropTypes.string,
};

export default ProfileGroup;
