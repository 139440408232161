import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';
import { CardBody } from '@gs-ux-uitoolkit-react/card';
import CardError from '../../../DashboardCards/Card/CardError';
import { ThreeSquaresLoader } from '../../../../../../../components/core/Loaders';
import translator from '../../../../../../../services/translator';

const { translate: t } = translator;

const MaintenanceAlertsBody = ({ loading, error, sortedCardRows, RowRenderer, onRowClick }) => {
  return (
    <CardBody className='dbcard__body'>
      {!loading && (!sortedCardRows.length || error) ? (
        <CardError errorMessage={error ? t('tkDashboardCardError') : t('tkFilterNoResultsFound')} />
      ) : loading ? (
        <ThreeSquaresLoader />
      ) : (
        <FixedSizeList itemData={sortedCardRows} innerElementType='ul' itemCount={sortedCardRows.length}
                       itemSize={50} height={250}>
          {({ index, style, data }) => (
            <li style={style} key={index}>
              <div className='dbcard__bodyRow' onClick={() => onRowClick(data[index])}>
                <RowRenderer {...data[index]} />
              </div>
            </li>
          )}
        </FixedSizeList>
      )}
    </CardBody>
  );
};

MaintenanceAlertsBody.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  sortedCardRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  RowRenderer: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired
};

export default MaintenanceAlertsBody;
