import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Card} from '@gs-ux-uitoolkit-react/card';
import './index.scss';

const CardComponent = ({children}) => (
    <Card className='dbcard gs-scrollbar gs-uitk-override-css' bordered elevation='00'>
        {children}
    </Card>
);

CardComponent.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(CardComponent);