import { useCallback } from 'react';
import useUserMaintenance from '../../context';
import useFetch from '../useFetch';
import useLoader from '../useLoader';
import endPointsMapper from '../../../../../configs/endPointsMapper';
import { customizedAccountsAgTree } from '../../utils/accountsTree';
import { EXTERNAL_USER_ACCOUNTS, UPDATE_ORGANIZATION_ACCOUNTS, UPDATE_CREATE_USER_ACCOUNT_DETAILS } from '../../actions';
import { CREATE_USER } from '../../constants';

export default function useGetOrganizationAccounts() {
  const {
    state: {
      userDetails: { isMosaicInternal },
      activeAccountsType,
    },
    dispatch,
    storeValues: { isInternalUser, pageKey },
  } = useUserMaintenance();

  const onSuccess = useCallback(
    (response, payload) => {
      if (pageKey === CREATE_USER) {
        dispatch({
          type: UPDATE_CREATE_USER_ACCOUNT_DETAILS,
          payload: { accountDetails: response.accountDetails, organizationId: payload.organizationId },
        });
      } else {
        if (isInternalUser && isMosaicInternal) {
          dispatch({
            type: UPDATE_ORGANIZATION_ACCOUNTS,
            payload: {
              operation: 'ADD',
              organizationId: response.id,
              accountDetails: customizedAccountsAgTree(response.accountDetails),
            },
          });
        } else {
          dispatch({
            type: EXTERNAL_USER_ACCOUNTS,
            payload: { key: activeAccountsType, accountDetails: customizedAccountsAgTree(response.accountDetails) },
          });
        }
      }

    },
    [isInternalUser, isMosaicInternal, activeAccountsType]
  );

  const { doFetch: getOrganizationAccounts, loading } = useFetch(endPointsMapper.FETCH_ORGANIZATION_ACCOUNTS, onSuccess);

  useLoader(loading);

  return { getOrganizationAccounts };
}
