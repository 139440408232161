import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@gs-ux-uitoolkit-react/modal';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Button } from '@gs-ux-uitoolkit-react/button';
import PropTypes from 'prop-types';
import useSnackBar from '../../../hooks/useSnackbar';
import useUserMaintenance from '../../../context';
import useValidateCreateNewUser from '../../../hooks/useValidateCreateNewUser';
import { ADD_UPDATE_USER_DETAILS, TAB_SWITCHED } from '../../../actions';
import { USER_DATA_IMPORTED_FROM_ID_HUB } from '../../../constants';
import { TABS } from "../TabSwitchBar"
import translator from '../../../../../../services/translator';
import './index.scss';

const { translate: t } = translator;

const ImportFromIDHubModal = ({ closeModal }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [noResultsFound, setNoResultsFound] = useState(false);

  const { validateCreateNewUser } = useValidateCreateNewUser();

  const { openSnackbar } = useSnackBar();

  const {
    state: { userDetails },
    dispatch,
  } = useUserMaintenance();

  const handleInputChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleClearClick = () => {
    setSearchTerm('');
    setNoResultsFound(false);
  };

  const handleSearch = async () => {
    if (searchTerm.length >= 3) {
      noResultsFound && setNoResultsFound(false);
      const response = await validateCreateNewUser({ emailIds: searchTerm });
      const { data: [userData] = [], success, error } = response;

      if (success && userData) {
        setSelectedUser(userData);
        setSearchTerm('');
      } else {
        setNoResultsFound(true);
      }

      if (error) {
        openSnackbar({ type: 'error', message: t('tkUserCreateNetworkErrorMsg') });
      }
    }
  };

  const handleKeyDown = event => {
    if (!(event.key === 'Enter')) return;
    handleSearch();
  };

  const handleSubmit = () => {
    const userDetailsCopy = { ...userDetails };
    userDetailsCopy.firstName = selectedUser.firstName;
    userDetailsCopy.lastName = selectedUser.lastName;
    userDetailsCopy.contactDetails.email = selectedUser.email;
    userDetailsCopy.login = selectedUser.login;
    userDetailsCopy.userGuid = selectedUser.userGuid;
    userDetailsCopy.isInternal = selectedUser.isPresentInIdHub;
    userDetailsCopy.isMosaicInternal = selectedUser.isPresentInMosaic;
    dispatch({ type: TAB_SWITCHED, payload: TABS.PROFILE });
    dispatch({ type: ADD_UPDATE_USER_DETAILS, payload: { userDetails: userDetailsCopy } });
    dispatch({ type: USER_DATA_IMPORTED_FROM_ID_HUB, payload: true });
    openSnackbar({ type: 'success', message: t('tkSuccessfullyImportedFromIDHub', selectedUser.firstName, selectedUser.lastName) });
    closeModal();
  };

  return (
    <Modal visible={true} placement='center' className='importFromIdHub-modal gs-uitk-override-css'>
      <ModalHeader onDismissButtonClick={closeModal} className='importFromIdHub-modal__header'>
        {t('tkImportFromIDHub')}
      </ModalHeader>
      <ModalBody className='importFromIdHub-modal__body'>
        <h3>{t('tkSearchUserByKerberos')}</h3>
        <Input
          type='search'
          placeholder={t('tkEnterKerberosOrEmail')}
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onClearClick={handleClearClick}
        />
        {selectedUser && (
          <section>
            <p className='importFromIdHub-modal__importMsg'>{t('tkImportUserIntoMosaic')}</p>
            <article className='importFromIdHub-modal__userContainer'>
              <div>
                <span className='importFromIdHub-modal__userIcon' />
              </div>
              <div className='importFromIdHub-modal__userInfo'>
                <div className='importFromIdHub-modal__userName'>{`${selectedUser.lastName}, ${selectedUser.firstName}`}</div>
                <div>
                  <div>{`${t('tkKerberos')}: ${selectedUser.login}`}</div>
                  <div>{`${t('tkEmail')}: ${selectedUser.email}`}</div>
                  <div>{`${t('tkGuid')}:  ${selectedUser.userGuid}`}</div>
                  <div>{`${t('tkStatus')}:  ${selectedUser.status}`}</div>
                </div>
              </div>
            </article>
          </section>
        )}
        {noResultsFound && <div className='importFromIdHub-modal__errorMsg'>{t('tkNoResultsFound')}.</div>}
      </ModalBody>
      <ModalFooter className='importFromIdHub-modal__footer'>
        <Button actionType='secondary' onClick={closeModal}>
          {t('tkModalCancelCTA')}
        </Button>
        <Button actionType='primary' onClick={selectedUser ? handleSubmit : handleSearch}>
          {t(`${selectedUser ? 'tkOk' : 'tkSearch'}`)}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ImportFromIDHubModal.propTypes = {
  closeModal: PropTypes.func,
};

export default ImportFromIDHubModal;
