import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import CardDropdown from '../../../DashboardCards/Card/CardDropdown';
import translator from '../../../../../../../services/translator';

const { translate: t } = translator;
const MaintenanceAlertsHeader = ({ loading, activeFilters, onFiltersUpdate }) => {

  return (
    <div className={cn('dbcard__controlpanel', { dbcard__loading: loading })}>
      <h4 className='dbcard__header'>{t('tkMaintenanceAlerts')}</h4>
      {!loading && (
        <>
          {activeFilters.map(({ selected, value, name }) => (
            <div key={name} className='dbcard__dropdown'>
              <CardDropdown name={name} selectedOption={selected} dropdownOptions={value} updateSelectedOption={onFiltersUpdate} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

MaintenanceAlertsHeader.propTypes = {
  loading: PropTypes.bool,
  activeFilters: PropTypes.arrayOf(
    PropTypes.shape({
      selected: PropTypes.string,
      value: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string
    })
  ),
  onFiltersUpdate: PropTypes.func
};

export default MaintenanceAlertsHeader;
