import { useCallback } from 'react';
import useFetch from '../useFetch';
import useUserMaintenance from '../../context';
import { UPDATE_CREATE_USER_DETAILS_FUNDS } from '../../actions';
import endPointsMapper from '../../../../../configs/endPointsMapper';

export default function useGetOrganizationAllFunds() {
  const { dispatch: ctxDispatch } = useUserMaintenance();

  const organizationAllFundsEndpoint = structuredClone(endPointsMapper.FETCH_ORGANIZATION_ALL_AVAILABLE_FUNDS);

  const onSuccess = useCallback((response) => {
    ctxDispatch({ type: UPDATE_CREATE_USER_DETAILS_FUNDS, payload: { funds: response } });
  }, []);

  const { doFetch } = useFetch(organizationAllFundsEndpoint, onSuccess);

  async function fetchOrganizationAllFunds({ organizationId }) {
    organizationAllFundsEndpoint.path = `${organizationAllFundsEndpoint.path}/${organizationId}/allAvailableFunds?status=OPEN&status=VIEW`;
    await doFetch();
  }

  return { fetchOrganizationAllFunds };
}
