import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import { Checkbox } from '@gs-ux-uitoolkit-react/checkbox';
import { Steps, Step } from '@gs-ux-uitoolkit-react/steps';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import Profile from '../Profile';
import Features from '../Features';
import Accounts from '../Accounts';
import Funds from '../Funds';
import Review from './../Review';
import HelpGuideTray from '../../../../../../components/core/HelpGuideTray';
import useValidateCreateNewUser from '../../../hooks/useValidateCreateNewUser';
import useSnackBar from '../../../hooks/useSnackbar';
import useGetOrganizationAccounts from '../../../hooks/useGetOrganizationAccounts';
import useGetOrganizationAllFunds from '../../../hooks/useGetOrganizationAllFunds';
import useCreateNewUser from '../../../hooks/useCreateNewUser';
import ImportFromIDHubModal from '../ImportFromIDHubModal';
import RouterPrompt from '../../../../../../components/core/RouterPrompt';
import Snackbar from '../../../../../../components/core/Snackbar';
import useUserMaintenance from '../../../context';
import useConsumeHelpGuide from '../../../../../../components/core/HelpGuideTray/useConsumeHelpGuide';
import { Conditional } from '../../../../../../components/core/Conditional';
import {
  TAB_SWITCHED,
  updateCurrentPage,
  UPDATE_ERROR,
  UPDATE_SELECTED_FUNDS,
  UPDATE_CREATE_USER_ACCOUNT_DETAILS,
  DELETE_ALL_ACCOUNT_ENTITLEMENTS_AND_PENDING_EDITS_FOR_CREATE,
} from '../../../actions';
import translator from '../../../../../../services/translator';
import { SNACKBAR_AUTO_DISMISS, CREATE_USER } from '../../../constants';
import { dispatchAnalytics, userDetailsTabChange } from '../../../analytics';
import { addPageContext } from '../../../../../../actions/page';
import { validateProfileData } from '../../UserDetails/Profile/validation';
import { PAGES } from '../../../reducers';
import './index.scss';

const { translate: t } = translator;

export const TABS = {
  PROFILE: 'Profile',
  FEATURES: 'Features',
  ACCOUNTS: 'Accounts',
  FUNDS: 'Funds',
  REVIEW: 'Review',
};

export const Tabs = ({ tabId, label, currentTab, switchTab }) => {
  return (
    <button className={cn('tab', { tab__active: currentTab === tabId })} onClick={() => switchTab(tabId)}>
      {label}
    </button>
  );
};

Tabs.propTypes = {
  tabId: PropTypes.string,
  label: PropTypes.string,
  currentTab: PropTypes.string,
  switchTab: PropTypes.func,
};

const TabSwitchBar = () => {
  const tabConfig = useMemo(() => {
    return [
      { id: TABS.PROFILE, label: 'tkProfile', index: 0 },
      { id: TABS.FEATURES, label: 'tkFeatures', index: 1 },
      { id: TABS.ACCOUNTS, label: 'tkAccounts', index: 2 },
      { id: TABS.FUNDS, label: 'tkFunds', index: 3 },
    ];
  }, []);

  const stepConfig = useMemo(() => {
    return [
      { id: TABS.PROFILE, label: 'tkUserProfile', index: 0 },
      { id: TABS.FEATURES, label: 'tkFeatures', index: 1 },
      { id: TABS.ACCOUNTS, label: 'tkAccounts', index: 2 },
      { id: TABS.FUNDS, label: 'tkFunds', index: 3 },
      { id: TABS.REVIEW, label: 'tkReview', index: 4 },
    ];
  }, []);

  const {
    state: {
      isPageEdited: isEdited,
      activeTab: currentTab,
      userDetails,
      modal: [unsavedChangesModal],
      isInputValid,
      saveBtnClicked,
      accountEntitlements,
      isEditable,
      createNewUserAccountOrgId,
      createNewUserFundOrgId,
      organizationAccounts,
      externalUserAccounts,
    },
    storeValues: { pageKey, isInternalUser },
    dispatch,
    reduxDispatch,
  } = useUserMaintenance();

  const [showSnackBar, setShowSnackBar] = useState(false);
  const { createNewUser } = useCreateNewUser();
  const { validateCreateNewUser } = useValidateCreateNewUser();
  const { openSnackbar } = useSnackBar();
  const { getOrganizationAccounts } = useGetOrganizationAccounts();
  const { fetchOrganizationAllFunds } = useGetOrganizationAllFunds();
  const { selectedRowData, handleNextBtn, handlePreviousBtn, toggleHelpGuideActive, helpGuideActive, closeHelpGuide } = useConsumeHelpGuide();

  const isPageEdited = useMemo(() => {
    return isEdited || (accountEntitlements && !!accountEntitlements.length);
  }, [isEdited, accountEntitlements]);

  const [displayImportIDHubModal, setDisplayImportIDHubModal] = useState(false);

  const switchTab = tabId => {
    closeHelpGuide();
    dispatch({ type: TAB_SWITCHED, payload: tabId });
    dispatchAnalytics(reduxDispatch, userDetailsTabChange(tabId));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const toggleHelpGuid = () => {
    helpGuideActive && setShowSnackBar(true);
    toggleHelpGuideActive(!helpGuideActive);
  };

  const switchStep = ({ stepIndex, manual }) => {
    const newIndex = stepConfig[stepIndex].index;
    const activeIndex = stepConfig.findIndex(item => item.id === currentTab);
    if (!manual && newIndex >= activeIndex) return;
    const newTabId = stepConfig.find(item => item.index === stepIndex).id;
    closeHelpGuide();
    dispatch({ type: TAB_SWITCHED, payload: newTabId });
    dispatchAnalytics(reduxDispatch, userDetailsTabChange(newTabId));
  };

  useEffect(() => {
    if (saveBtnClicked) {
      closeHelpGuide();
    }
  }, [saveBtnClicked]);

  const currentStep = useMemo(() => {
    if (pageKey === CREATE_USER) {
      return stepConfig.find(item => item.id === currentTab);
    }
  }, [pageKey, stepConfig, currentTab]);

  // eslint-disable-next-line complexity, max-statements
  const onNext = async () => {
    const configIndex = stepConfig.find(item => item.id === currentTab).index;
    if (configIndex === 0) {
      const { success, validationError } = await validateProfileData(userDetails);
      dispatch({ type: UPDATE_ERROR, payload: { ...isInputValid, ...validationError } });
      if (!success) return;
      const response = await validateCreateNewUser({ emailIds: userDetails?.contactDetails?.email });
      const [validationData] = response.data;
      if (response.success) {
        if (!validationData) {
          switchStep({ manual: true, stepIndex: configIndex + 1 });
        } else {
          const [{ isPresentInMosaic, isPresentInIdHub }] = response.data;
          if (isInternalUser && isPresentInIdHub && isPresentInMosaic) {
            openSnackbar({ type: 'error', message: t('tkUserCreateAlreadyExistsErrorMsg') });
          } else if (isInternalUser && isPresentInIdHub && !isPresentInMosaic) {
            switchStep({ manual: true, stepIndex: configIndex + 1 });
          } else if (isInternalUser && !isPresentInIdHub && isPresentInMosaic) {
            openSnackbar({ type: 'error', message: t('tkUserCreateNetworkErrorMsg') });
          } else if (isInternalUser && !isPresentInIdHub && !isPresentInMosaic) {
            switchStep({ manual: true, stepIndex: configIndex + 1 });
          } else if (!isInternalUser && isPresentInIdHub && isPresentInMosaic) {
            openSnackbar({ type: 'error', message: t('tkUserCreateAlreadyExistsErrorMsg') });
          } else if (!isInternalUser && isPresentInIdHub && !isPresentInMosaic) {
            openSnackbar({ type: 'error', message: t('tkUserCreateNetworkErrorMsg') });
          } else if (!isInternalUser && !isPresentInIdHub && isPresentInMosaic) {
            openSnackbar({ type: 'error', message: t('tkUserCreateNetworkErrorMsg') });
          } else if (!isInternalUser && !isPresentInIdHub && !isPresentInMosaic) {
            switchStep({ manual: true, stepIndex: configIndex + 1 });
          }
        }
      } else {
        openSnackbar({ type: 'error', message: t('tkAnErrorPleaseTryAgain') });
      }
    } else {
      switchStep({ manual: true, stepIndex: configIndex + 1 });
      if (configIndex === 1) {
        if (!(userDetails.isInternal && userDetails.isMosaicInternal)) {
          if (createNewUserAccountOrgId !== userDetails.organizationId) {
            dispatch({ type: DELETE_ALL_ACCOUNT_ENTITLEMENTS_AND_PENDING_EDITS_FOR_CREATE });
            dispatch({ type: UPDATE_CREATE_USER_ACCOUNT_DETAILS, payload: { accountDetails: [] } });
            await getOrganizationAccounts({ organizationId: userDetails.organizationId, type: 'AVAILABLE' });
          }
          !isEmpty(organizationAccounts) && dispatch({ type: DELETE_ALL_ACCOUNT_ENTITLEMENTS_AND_PENDING_EDITS_FOR_CREATE });
        } else {
          if (externalUserAccounts.entitled.length || externalUserAccounts.available.length) {
            dispatch({ type: DELETE_ALL_ACCOUNT_ENTITLEMENTS_AND_PENDING_EDITS_FOR_CREATE });
            dispatch({ type: UPDATE_CREATE_USER_ACCOUNT_DETAILS, payload: { accountDetails: [] } });
          }
        }
      } else if (configIndex === 2) {
        if (createNewUserFundOrgId !== userDetails?.organizationId) {
          dispatch({ type: UPDATE_SELECTED_FUNDS, payload: { excludedList: [], includedList: [], fundEntitlementCriteria: [] } });
          await fetchOrganizationAllFunds({ organizationId: userDetails?.organizationId });
        }
      }
    }
  };

  const onPrevious = () => {
    switchStep({ manual: true, stepIndex: stepConfig.find(item => item.id === currentTab).index - 1 });
  };

  const onCancel = () => {
    reduxDispatch(updateCurrentPage(PAGES.LANDING_PAGE));
    reduxDispatch(addPageContext({ pageKey: null }));
  };

  const onConfirm = async () => {
    const { includedList, excludedList } = userDetails.funds;
    const funds = {
      includedList: includedList.map(included => included.id),
      excludedList: excludedList.map(excluded => excluded.id),
    };
    // Accounts -> taking only latest change to firm/branch/fundAccount
    const accountEntitlementsCopy = JSON.parse(JSON.stringify(accountEntitlements));
    const indexesToBeDeleted = [];
    for (let i = 0; i < accountEntitlementsCopy.length - 1; i++) {
      const item1 = accountEntitlementsCopy[i];
      delete item1.capabilities;
      for (let j = i + 1; j < accountEntitlementsCopy.length; j++) {
        const item2 = accountEntitlementsCopy[j];
        delete item2.capabilities;
        if (isEqual(item1, item2)) {
          indexesToBeDeleted.push(i);
        }
      }
    }
    const finalAccountEntitlements = JSON.parse(JSON.stringify(accountEntitlements));
    if (indexesToBeDeleted.length) {
      let i = indexesToBeDeleted.length;
      while (i--) {
        finalAccountEntitlements.splice(indexesToBeDeleted[i], 1);
      }
    }
    await createNewUser([
      {
        ...userDetails,
        accountDetails: [],
        accountEntitlements: finalAccountEntitlements,
        funds: {
          ...userDetails.funds,
          ...funds,
        },
      },
    ]);
  };

  const openIdHubModal = () => {
    setDisplayImportIDHubModal(true);
  };

  const closeIdHubModal = () => {
    setDisplayImportIDHubModal(false);
  };

  return (
    <React.Fragment>
      <RouterPrompt when={!!isPageEdited} title={t('tkWarning')} content={unsavedChangesModal.content} />
      {displayImportIDHubModal && <ImportFromIDHubModal closeModal={closeIdHubModal} />}
      <React.Fragment>
        <div className='user-profile-container' data-testid='user-profile-container'>
          {helpGuideActive && showSnackBar && <Snackbar hideTime={SNACKBAR_AUTO_DISMISS} type='info' autoHide={true} msgCopy={t('tkHelpGuide')} />}
          <div className='tabswitchbar' data-testid='tabswitch-container'>
            <div className='tabswitchbar__left'>
              {pageKey === CREATE_USER ? (
                <Steps onStepClick={switchStep} currentStep={currentStep.index} orientation='horizontal'>
                  {stepConfig.map(tab => (
                    <Step
                      icon={currentStep.index === tab.index && null}
                      status={currentStep.index === tab.index && 'success'}
                      key={tab.id}
                      label={t(tab.label)}
                    />
                  ))}
                </Steps>
              ) : (
                <>
                  {tabConfig.map(tab => (
                    <Tabs key={tab.id} tabId={tab.id} label={t(tab.label)} currentTab={currentTab} switchTab={switchTab} />
                  ))}
                </>
              )}
            </div>
            <div className='tabswitchbar__right'>
              <Checkbox id='toggle-help-guide' checked={helpGuideActive} onChange={toggleHelpGuid}>
                {t('tkTurnOnHelpGuide')}
              </Checkbox>
              {pageKey === CREATE_USER && (
                <Button actionType='primary' emphasis='minimal' onClick={openIdHubModal}>
                  <Icon name='database' type='filled' />
                  {t('tkImportFromIDHub').toUpperCase()}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div
          className={cn(`details-container details-container-${currentTab}`, {
            'fullwidth-tab': currentTab === TABS.FUNDS,
            pageInEditMode: isEditable,
          })}
          data-testid='usersearch-details-tabs'>
          <Conditional condition={currentTab === TABS.PROFILE}>
            <div className='tab-content' data-testid='profile-tab-content'>
              <Profile />
            </div>
          </Conditional>
          <Conditional condition={currentTab === TABS.FEATURES}>
            <div className='tab-content' data-testid='features-tab-content'>
              <Features />
            </div>
          </Conditional>
          <Conditional condition={currentTab === TABS.ACCOUNTS}>
            <div className='tab-content' data-testid='accounts-tab-content' style={{ paddingTop: 0 }}>
              <Accounts />
            </div>
          </Conditional>
          <Conditional condition={currentTab === TABS.FUNDS}>
            <div className='tab-content' data-testid='funds-tab-content'>
              <Funds />
            </div>
          </Conditional>
          <Conditional condition={currentTab === TABS.REVIEW}>
            <div className='tab-content' data-testid='funds-tab-content'>
              {pageKey === CREATE_USER && <Review />}
            </div>
          </Conditional>
          {pageKey === CREATE_USER && (
            <div className='usersearch-details__footer'>
              <Button onClick={onCancel} actionType='destructive' emphasis='subtle'>
                {t('tkCancel')}
              </Button>
              {currentTab !== TABS.PROFILE && (
                <Button onClick={onPrevious} actionType='primary' emphasis='subtle'>
                  {t('tkPrevious')}
                </Button>
              )}
              {currentTab !== TABS.REVIEW ? (
                <Button onClick={onNext} actionType='primary' emphasis='bold'>
                  {t('tkNext')}
                </Button>
              ) : (
                <Button onClick={onConfirm} actionType='primary' emphasis='bold'>
                  {t('tkConfirm')}
                </Button>
              )}
            </div>
          )}
          {!!selectedRowData && helpGuideActive && (
            <HelpGuideTray
              selectedRowData={selectedRowData}
              closeHelpGuide={closeHelpGuide}
              handlePreviousBtn={handlePreviousBtn}
              handleNextBtn={handleNextBtn}
            />
          )}
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default TabSwitchBar;
